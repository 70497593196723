import { StylesResponse } from "../config"
import { logging } from "../utils/logging"
import { componentServiceBaseUrl } from "./config"

export const getStyles = async(shop: string, shopDomain: string) => {
  try {
    const config : RequestInit = {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'shop': shop,
        'shop-domain': shopDomain,
      },
      cache: 'default'
    }

    const url = componentServiceBaseUrl + '/v1/style?v=' + Date.now()
    const response: StylesResponse = await fetch(url, config)
    .then(async (response) => {
      if (!response.ok) {
        const errBody = await response.json()
        throw new Error(`request failed with status ${response.status}: ${errBody.error}`)
      }
      return response.json()
    })
    .catch(err => {
      throw new Error(`fetch request failed: ${err}`)
    })

    return response

  } catch (error) {
    logging(error, {tags: {section: 'getStyles'}})
  }
}