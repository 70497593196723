const defaultFonts = [
  'Roboto',
  'Helvetica',
  'Arial',
  'sans-serif',
]

export const fontWithDefaults = (font?: string) => {
  font = font || ''
  font = `"${font}"`

  return (
    [
      font,
      ...defaultFonts,
    ]
    .join(',')
  )
}
