import { ReactElement } from 'react'
import { getElementFromHtmlString } from './getElementFromHtmlString'
import { CurrencySymbol } from './api/getProductPrimitives'
import { DeepPartial } from './utils/helpers'
import { CategoryPrimitiveContent, ContainerConfig, PrimitiveType } from './api/components'

export type Vendor = 'shopify' | 'salesforce-sitegenesis'

export type CartItem = {
  variant: {
    id: string
    title: string
    parentTitle: string
    images: ImageType[]
    externalId: string
    price: number
    comparePrice: number | null
    parentImage: ImageType
    currency: string
    maxQuantity: number
    optionValues: OptionValue[]
    allowOosOrder: boolean
  }
  quantity: number
}

export type Activable = {
  active: boolean
}

export type ConfigState = {
  stateLoaded: boolean

  variant?: VariantType

  variantStates?: {[key: string]: DeepPartial<ConfigState>}

  title: Activable & {
    value: string
  }

  variantTitle: Activable & {
    value: string
  }

  firstInStockVariant: string

  campaignName: Activable & {
    value: string
  }

  campaignDescription: Activable & {
    value: string
  }

  quantity: Activable & {
    value: number
  }

  comparePrice: Activable & {
    value: number | null
  }

  price: Activable & {
    value: number
    currency: string
  }

  sku: Activable & {
    value: string
  }

  addToCart: Activable & {
    // url: what to do on click
  }

  outOfStock: Activable & {
  }

  description: Activable & {
    value: ReactElement
  }

  imageIndex: number
  images: ImageType[]
  variantImages: ImageType[]

  // NOTE: use the same name as OptionType['name']
  options: {[optionName: string]: OptionType}

  hooks: Hook[]
}

export type RawCategory = {
  id: string
  external_id: string
  title: string
  description?: string
  cdp_url?: string
  featured_image?: {
    url: string
  }
}

export type Category = {
  id: string
  description?: ReactElement
  image?: MultiSourceImage
  originalUrl?: string
  largeUrl?: string
  mediumUrl?: string
  smallUrl?: string
  altText?: string
  title: string
  url: string
  type: PrimitiveType
}

export type Hook = {
  rawHook: ExperienceConfigHook
  categories?: Category[]
  products: HookProduct[]
  // TODO: decouple hook types
  htmls?: ReactElement[]
}

export type HookProduct = {
  id: string
  image: MultiSourceImage
  title: string
  url: string
  description: ReactElement
  currency: string
  price: number
  comparePrice: number | null
  experienceUrl?: string
  type: PrimitiveType
}

export type Product = {
  id: string
  quantity: number
}

export const initialState: ConfigState = {
  stateLoaded: false,

  title: {
    active: false,
    value: '',
  },

  variantTitle: {
    active: false,
    value: '',
  },

  firstInStockVariant: '',

  campaignName: {
    active: false,
    value: '',
  },

  campaignDescription: {
    active: false,
    value: '',
  },

  quantity: {
    active: false,
    value: 1,
  },

  comparePrice: {
    active: false,
    value: null,
  },

  price: {
    active: false,
    value: 0,
    currency: '',
  },

  sku: {
    active: false,
    value: '',
  },

  addToCart: {
    active: false,
  },

  outOfStock: {
    active: false,
  },

  description: {
    active: false,
    value: getElementFromHtmlString(''),
  },

  imageIndex: 0,
  images: [] as ImageType[],
  variantImages: [] as ImageType[],

  options: {},

  hooks: [],
}

export type OptionType = Activable & {
  name: string
  value: string // current value
  position: number
  values: string[]
}

export type ImageType = MultiSourceImage & {
  isVideo?: boolean
  videoThumbnail?: string
  video?: {
    url?: string
    thumbnail?: string
  }
}

export type MultiSourceImage = {
  originalUrl: string
  largeUrl: string
  mediumUrl: string
  smallUrl: string
  altText: string
}

export type VariantType = {
  available: boolean
  maxQuantity: number
  id: string
  externalId: string
  sku: string
  price: number
  comparePrice: number | null
  title: string
  parentTitle: string
  currency: CurrencySymbol
  optionsMatch: string
  parentImage: ImageType
  images: ImageType[]
  optionValues: OptionValue[]
  allowOosOrder: boolean
}

export const initialConfig: Config = {
  baseState: {},
  variantStates: {},
  editedState: {},
}

export type NormalizedProductDetail = {
  id: string
  externalId?: string
  title: string
  description: ReactElement
  featuredImage?: ImageType
  images: ImageType[]
  pdpUrl?: string
  cdpUrl?: string
  options: {[optionName: string]: OptionType}
  variants: VariantType[]
  firstInStockVariant?: string
  type: PrimitiveType
}

export type Config = {
  baseState: DeepPartial<ConfigState>
  // set from outside, key must match stringified option names
  variantStates: {[key: string]: DeepPartial<ConfigState>}
  firstInStockVariant?: string
  // set by user actions or code effects
  editedState: DeepPartial<ConfigState>
}

export type ExperienceConfigHook = {
  obj_id: string
  title: string
} & (
  {
    type: 'all_published_experiences'
  }
  | {
    type: 'same_category'
    sort_by?: SortBy
  }
  | {
    type: 'manual_selection'
    // NOTE: backend ignores empty lists
    product_ids: {obj_id: string, product_id: string}[]
  }
  | {
    type: 'manual_category_selection'
    categories: {obj_id: string, category_id: string}[]
  }
  | {
    type: 'category'
    categories: {obj_id: string, category_id: string}[]
    blacklisted_products: {obj_id: string, product_id: string}[]
    sort_by?: SortBy
    extended: boolean
  }
  | {
    type: 'html'
    htmls: {obj_id: string, html: string}[]
  }
  | {
    type: 'newsletter'
    text: string
    buttonText: string
  }
  | {
    type: 'notify_out_of_stock'
    text: string
    buttonText: string
  }
)

export interface SortBy {
  field: 'price' | 'name' | 'sales' | 'title' // Title is the same as name, but is probably used by mistake is UI
  direction: 'asc' | 'desc'
}

export type RawImage = {
  url: string
  alt?: string
  thumbnailUrl?: string
}

export type RawOption = {
  name: string
  values: {value: string}[]
  position: number
}

export type ProductImage = MultiSourceImage & {
}

export type Option = {
  name: string
  values: string[]
}

export type OptionValue = {
  name: string
  value: string
}

export type VariantDetail = {
  id: string
  productId: string
  externalId: string
  sku: string
  title: string
  productTitle: string
  price: number
  comparePrice: number | null
  currencyCode: string
  optionValues: OptionValue[]
  featuredImage?: ProductImage
  images: ProductImage[]
  available: boolean
  allowOosOrder: boolean
  quantity: number
}

export type ProductDetail = {
  id: string
  externalId: string
  title: string
  description: string
  featuredImage?: ProductImage
  images: ProductImage[]
  pdpUrl?: string
  cdpUrl?: string
  options: Option[]
  variants: VariantDetail[]
  firstInStockVariant: string
}

export type RawProduct = {
  id: string
  external_id: string
  title?: string
  description?: string
  featured_image?: RawImage
  images?: RawImage[]
  pdp_url?: string
  status?: string
  options?: RawOption[]
  variants: {
    available: boolean
    allow_oos_order: boolean
    quantity: number
    id: string
    external_id: string
    title: string
    sku?: string
    currency_code: string
    price?: number
    compare_price?: number
    option_values: {[name: string]: string}
    featured_image?: RawImage
  }[]
}

export type ExperienceConfig = {
  id: string
  name: string
  shop: string
  layout: '' | 'single_product' | 'social_home' | 'category' | 'components'
  last_modified: string
  products: {obj_id: string, product_id: string}[]
  // NOTE: this contains post ids, which have to be fetched separately
  campaign_images: (MultiSourceImage & {
    obj_id: string,

    is_video?: boolean
    video_thumbnail?: string

    post_type?: 'instagram' | 'ad',

    // TODO: correct to string
    post_account_id?: string,
    post_id?: string,
  })[]
  campaign_description: string
  componentOrderId: string
  start_date?: number
  end_date?: number
  // status: 'PUBLISHED' | 'UNPUBLISHED'
  experience_url?: string
  fishhooks: ExperienceConfigHook[]
  redirect_url?: string
}

export type PageConfig = {
  id: string
  internalName: string
  headline: string
  subheadline: string

  layout: '' | 'SINGLE_PRODUCT' | 'SOCIAL_HOME' | 'CATEGORY' | 'COMPONENTS' | 'EMBEDDED_COMPONENTS'

  // note, this field is only used at runtime. ideally we'd have different types for runtime and serialization, but this was a quick solution
  components: ContainerConfig[]
  componentIds: string[]
  componentOrderId: string
  pageComponentOverrides: PageComponentOverrides
  mlOrderingUsed: boolean[]

  startDate?: number
  endDate?: number

  createdAt: number
  updatedAt: number

  redirectUrl: string
  pageUrl: string
  pageUrlWithUtms: string
  utmCampaign: string
  utmSource: string
  utmMedium: string

  products: {
    objId: string
    productId: string
  }[]
  categories?: CategoryPrimitiveContent['value']
  campaignMedias: (MultiSourceImage & {
    objId: string

    isVideo?: boolean
    videoThumbnail?: string

    postType?: 'instagram' | 'ad'

    postAccountId?: string
    postId?: string
    video? : {
      url?: string
      thumbnail?: string
    }
  })[]
}

export type PageComponentOverrides = {
  [componentId: string]: {
    title?: string;
    suppressedProducts?: string[]
  }
}

export type StyleFont = {
  name: string
  url: string
}
export type StylesResponse = {
  colors: {
    primary: string
    secondary: string
    logoBackground: string
  }
  fonts: {
    main: StyleFont
    header: StyleFont
  }
  logoUrl: string
}
