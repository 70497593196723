import { FC, useEffect, useRef } from 'react'

export const Embeded: FC<{html: string}> = ({html}) => {
  const ref = useRef<any>(null)

  useEffect(() => {
    const r = ref.current
    if (!r) {
      console.warn('Ref not found')
      return
    }
    r.innerHTML = '' // clear previous
    r.append(document.createRange().createContextualFragment(html))
  }, [html])

  return (
  <div ref={ref} />
  )
}
