import React, { createContext, FC, ReactNode, useEffect, useReducer, useState } from 'react'
import { useLocation } from 'react-router-dom'
import merge from 'ts-deepmerge'
import { Embeded } from '../components/Embeded'
import { getInitialLegacyFields, LegacyFields } from '../components/legacyFields'
import {
  CartItem,
  Config,
  ConfigState,
  ExperienceConfigHook,
  Hook,
  HookProduct,
  initialConfig,
  initialState,
  MultiSourceImage,
  NormalizedProductDetail,
  OptionType,
  PageConfig,
  Product,
  SortBy,
  Vendor
} from '../config'
import { DEFAULT_PRODUCT_LIMIT } from '../consts'
import { GetCategoryPrimitivesOptions, NormalizedCategoryPrimitive } from '../api/getCategoryPrimitives'
import { getElementFromHtmlString } from '../getElementFromHtmlString'
import { getExperienceFolderUrl } from '../getExperienceFolderUrl'
import { getProductPrimitives, GetProductPrimitivesOptions } from '../api/getProductPrimitives'
import { getProductDetails, GetProductDetailsOptions } from '../api/getProductsByIds'
import { getShopUrl } from '../getShopUrl'
import { getVariantDetails } from '../api/getVariants'
import { getPageConfigs } from '../api/getPageConfigs'
import { cartesian, deepCloneJson, DeepPartial } from '../utils/helpers'
import { logging, nonErrorlog } from '../utils/logging'
import { getInitialPageConfig } from './getInitialPageConfig'
import { fetchJsTag, initJsTag } from './initJsTag'
import { getCategoryPrimitives } from '../api/getCategoryPrimitives'
import { ContainerConfig } from '../api/components'
import { getInitialFooterConfig } from './getInitialFooterConfig'
import { getComponentConfig } from '../api/getComponentConfig'
import { getInitialHeaderConfig } from './getInitialHeaderConfig'
import {hostOverrideName} from "../api/config";
import {defaultPrimitiveSettings, PrimitiveSettings} from "../api/primitiveSettings";

// TODO: add set baseState and variantState action
// TODO: add set editedState action
export type ConfigAction = {
  type: 'setPartialConfig'
  override?: boolean
  value: DeepPartial<Config>
}

export const getMultiSourceImageWithDefaults = (s?: Partial<MultiSourceImage>): MultiSourceImage => {
  const fallback: string = (s as any)?.['url'] || ''
  return {
    ...s,
    smallUrl: s?.smallUrl || fallback,
    mediumUrl: s?.mediumUrl || s?.smallUrl || fallback,
    largeUrl: s?.largeUrl || s?.mediumUrl || s?.smallUrl || fallback,
    originalUrl: s?.originalUrl || s?.largeUrl || s?.mediumUrl || s?.smallUrl || fallback,
    altText: s?.altText || fallback,
  }
}

export const configReducer = (config: Config, action: ConfigAction): Config => {
  if (action.type === 'setPartialConfig') {
    const a = merge.withOptions(
      {mergeArrays: false},
      initialConfig,
      action.override ? {} : config,
      action.value,
    )
    return a
  }

  console.warn("Unknown config reducer action", action)
  return config
}

export type ConfigContextType = {
  searchParams: URLSearchParams
  shop: string
  shopDomain: string
  vendor: Vendor
  experienceFolderUrl: string
  logoUrl: string
  setLogoUrl: React.Dispatch<React.SetStateAction<string>>
  primitiveSettings: PrimitiveSettings,
  setPrimitiveSettings: React.Dispatch<React.SetStateAction<PrimitiveSettings>>
  state: DeepPartial<ConfigState>
  configDispatch: React.Dispatch<ConfigAction>
  pageConfig: PageConfig
  setPageConfig: React.Dispatch<React.SetStateAction<PageConfig>>
  footerConfig: ContainerConfig
  setFooterConfig: React.Dispatch<React.SetStateAction<ContainerConfig>>
  headerConfig: ContainerConfig
  setHeaderConfig: React.Dispatch<React.SetStateAction<ContainerConfig>>
  cartItems: CartItem[]
  updateCartItem: (x: CartItem) => CartItem[]
  categoryProductQueryOpts?: GetCategoryPrimitivesOptions
  sameCategoryProductQueryOpts?: GetCategoryPrimitivesOptions

  legacyFields: LegacyFields
  setLegacyFields: React.Dispatch<React.SetStateAction<LegacyFields>>
}



export const initialContext = {
  searchParams: new URLSearchParams(),

  shop: '',
  shopDomain: '',
  vendor: '' as any,

  experienceFolderUrl: '',

  logoUrl: '',
  setLogoUrl: (x: any) => {console.warn('noop', x)},

  primitiveSettings: defaultPrimitiveSettings,
  setPrimitiveSettings: (x: any) => {console.warn('noop', x)},

  state: initialConfig.baseState,
  configDispatch: (x: any) => {console.warn('noop', x)},

  pageConfig: getInitialPageConfig(),
  setPageConfig: (x: any) => {console.warn('noop', x)},

  footerConfig: getInitialFooterConfig(),
  setFooterConfig: (x: any) => {console.warn('noop', x)},

  headerConfig: getInitialHeaderConfig(),
  setHeaderConfig: (x: any) => {console.warn('noop', x)},

  cartItems: [],
  updateCartItem: (x: any) => {console.warn('noop', x); return []},

  legacyFields: getInitialLegacyFields(),
  setLegacyFields: (x: any) => {console.warn('noop', x)},
}
export const ConfigContext = createContext<ConfigContextType>(initialContext)

export const getOptionsList = (options: ConfigState['options']) => {
  // NOTE: These must be sorted by position, the backend purposefully sends them in a specific order
  return Object.values(options).sort((a, b) => a.position - b.position)
}

export const getOptionsMatch = (options: OptionType[]): string => {
  return options.map(x => x.value).join(',')
}

const defaultSelectedProduct = {
  id: '',
  description: getElementFromHtmlString(''),
  title: '',
  variants: [],
  options: {},
  cdpUrl: '',
  images: [],
  type: '' as any,
}

const syncCartProduct = (product: Product, vendor: Vendor) => {
  const setProduct = (window as any).sdxCapture?.setProduct
  if (typeof setProduct !== 'function') {
    console.warn('set product not found')
    logging('set product not found', { tags: { section: 'Sync cart product'} })
    return
  }
  nonErrorlog(`Called sdx syncCartProduct ${{...product}}`);
  setProduct({
    ...product,
    id: product.id,
  })
}

export const ConfigContextProvider: FC<{children: ReactNode}> = ({children}) => {
  const { search } = useLocation()
  const [legacyFields, setLegacyFields] = React.useState<LegacyFields>(getInitialLegacyFields())

  const [searchParams, setSearchParams] = useState<URLSearchParams>(new URLSearchParams(search))
  const [shop, setShop] = useState('')
  const [shopDomain] = useState(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const queryHostname = urlParams.get(hostOverrideName)
    return queryHostname || window.location.hostname
  })

  const [vendor, setVendor] = useState<Vendor>('' as any)
  const [experienceFolderUrl, setExperienceFolderUrl] = useState('')
  const [logoUrl, setLogoUrl] = useState('')
  const [primitiveSettings, setPrimitiveSettings] = useState(defaultPrimitiveSettings)

  const [pageConfig, setPageConfig] = useState<PageConfig>(getInitialPageConfig())
  const [footerConfig, setFooterConfig] = useState<ContainerConfig>(getInitialFooterConfig())
  const [headerConfig, setHeaderConfig] = useState<ContainerConfig>(getInitialHeaderConfig())

  const [cartItems, setCartItems] = useState<CartItem[]>([])
  const [config, configDispatch] = useReducer(configReducer, initialConfig)
  const [state, setState] = useState(initialState)

  const categoryProductQueryOpts = React.useRef<GetCategoryPrimitivesOptions>()
  const sameCategoryProductQueryOpts = React.useRef<GetProductPrimitivesOptions>()
  const [lastProcessedHash, setLastProcessedHash] = useState('')

  // TODO: use reducer
  const updateCartItem = (cartItem: CartItem) => {
    try {
      let newCartItems = [...cartItems]
      const indexOfExisting = newCartItems.findIndex(x => x.variant.id === cartItem.variant.id)
      // if found replace
      if (indexOfExisting > -1) {
        newCartItems[indexOfExisting] = cartItem
      }
      // else append
      else {
        newCartItems.push(cartItem)
      }
      newCartItems = newCartItems.filter(x => x.quantity > 0)
      syncCartProduct({id: cartItem.variant.externalId, quantity: cartItem.quantity}, vendor)
      setCartItems(newCartItems)
      // serialize(newCartItems, 'cartItems')
      return newCartItems
    }
    catch (error) {
      logging(error, { tags: { section: 'updateCartItems'} })
      return [...cartItems]
    }
  }

  useEffect(() => {
    if (!shop) return

    const fetchFooter = async () => {
      try {
        getComponentConfig('', shop, 'FOOTER').then((response) => {
          // TODO: separate this to two different functions as providing
          //  componentType parameter makes a different return signature
          var r = response as any
          if (r?.length > 0) setFooterConfig(r[0])
        })
      }
      catch (err) {
        logging(err, {tags: {section: 'fetchFooter'}})
      }
    }
    fetchFooter()
  }, [shop])

  useEffect(() => {
    if (!shop) return

    const fetchHeader = async () => {
      try {
        getComponentConfig('', shop, 'HEADER').then((response) => {
          // TODO: separate this to two different functions as providing
          //  componentType parameter makes a different return signature
          var r = response as any
          if (r?.length > 0) setHeaderConfig(r[0])
        })
      }
      catch (err) {
        logging(err, {tags: {section: 'fetchHeader'}})
      }
    }
    fetchHeader()
  }, [shop])

  useEffect(() => {
    setSearchParams(new URLSearchParams(search))
  }, [search])

  useEffect(() => {
    var newShop = getShopUrl(searchParams)
    setShop(newShop)

    // NOTE: for now it's either shopify or salesforce, later on we can use GET /v1/merchant with shop in header
    if (newShop.includes('shopify')) setVendor('shopify')
    else setVendor('salesforce-sitegenesis')

    setExperienceFolderUrl(getExperienceFolderUrl(searchParams))
  }, [searchParams])

  useEffect(() => {
    if (!pageConfig.id) return
    const onCartUpdate = (event: Event) => {
      if (!pageConfig.id) return

      const cartProducts = (event as any).detail as Product[]

      if (cartProducts.length === 0) return

      // TODO: this should live in separate file
      const asyncGetVariants = async () => {
        try {
          const variantDetails = await getVariantDetails(shop, {
            variantExternalIds: cartProducts.map(p => p.id),
          })
          const newCartItems: CartItem[] = []
          cartProducts.forEach(cartProduct => {
            const variantDetail = variantDetails.find(x => x.externalId === cartProduct.id)
            if (variantDetail && cartProduct.quantity > 0) {
              newCartItems.push({
                variant: {
                  id: variantDetail.id,
                  title: variantDetail.title,
                  parentTitle: variantDetail.productTitle,
                  images: [],
                  externalId: variantDetail.externalId,
                  price: variantDetail.price,
                  comparePrice: variantDetail.comparePrice,
                  parentImage: getMultiSourceImageWithDefaults(variantDetail.featuredImage),
                  currency: variantDetail.currency,
                  maxQuantity: variantDetail.quantity,
                  optionValues: variantDetail.optionValues,
                  allowOosOrder: variantDetail.allowOosOrder,
                },
                quantity: cartProduct.quantity,
              })
            }
            else {
              logging(`Unable to find variant by id: ${cartProduct.id}`, { tags: { section: 'asyncGetVariants - productServiceUrl'} })
              console.warn(`Unable to find variant by id: ${cartProduct.id}`)
            }
          })

          setCartItems(newCartItems)
        } catch (error) {
          logging(error, { tags: { section: 'asyncGetVariants'} })
        }
      }
      asyncGetVariants()
      if (typeof (window as any).sdxCapture?.flushCart !== 'function') {
        console.warn('cart flush not found')
        logging('cart flush not found', { tags: { section: 'Flush cart'} })
      }
      nonErrorlog(`Called sdx flushcart`);
      ;(window as any).sdxCapture?.flushCart()
    }

    document.addEventListener("cart:update", onCartUpdate)
    return () => {
      document.removeEventListener("cart:update", onCartUpdate)
    }
  }, [pageConfig, shop, vendor])

  useEffect(() => {
    fetchJsTag()
  }, [])

  useEffect(() => {
    if (shop) initJsTag(shop)
  }, [shop])

  useEffect(() => {
    // if(!pageConfig.id) return

    // TODO: why react hook is triggered multiple times? which properties changed?
    // NOTE: object properties order is not guaranteed for JSON.stringify to be used on the whole pageConfig
    const hash = JSON.stringify([
      pageConfig.id,
      pageConfig.layout,
      pageConfig.headline,
      pageConfig.subheadline,
      legacyFields.fishhooks,
      shop,
      pageConfig.campaignMedias,
      pageConfig.products,
      pageConfig.categories,
    ])
    if(lastProcessedHash === hash) return

    if (pageConfig.layout === 'COMPONENTS') return

    const asyncStuff = async () => {
      // We need to decouple fetching data for the hooks from fetching data for the main product. If data fetching
      // for a hook fails, the main product should still show up okay. Right now, entire code block skips to catch without setting anything in state.
      try {
      let selectedProduct: NormalizedProductDetail | undefined = undefined

      if (pageConfig.layout === 'SINGLE_PRODUCT' && pageConfig.products?.length !== 0) {
        const opts: GetProductDetailsOptions = {}
        // productIds
        opts.productIds = pageConfig.products?.map(x => x.productId) || []
        const productDetails = await getProductDetails(shop, opts)

        if ((pageConfig.products?.length || 0) > 0)
          selectedProduct = productDetails?.[0]

        if(selectedProduct && selectedProduct.externalId) {
          ;(window as any).__sdx_product_externalId = selectedProduct.externalId
        }
      }

      const shouldIgnoreDynamicCategoryHook =
        pageConfig.layout === 'CATEGORY' &&
        legacyFields.fishhooks[0]?.type === 'category' &&
        pageConfig.id === 'dynamic'

      if (pageConfig.layout === 'CATEGORY') {
        // NOTE: category hook will be stored first on the builder!
        const categoryHook: ExperienceConfigHook = legacyFields.fishhooks[0]
        if (categoryHook?.type === 'category') {
          const categoryId = categoryHook.categories[0]?.category_id || ''
          const opts: GetCategoryPrimitivesOptions = {}
          opts.categoryIds = [categoryId]
          if(!categoryId) {
            selectedProduct = defaultSelectedProduct
          }
          else {
            const categoryPrimitives: NormalizedCategoryPrimitive[] | undefined = await getCategoryPrimitives(shop, opts)
            if (categoryPrimitives) {
              const categoryPrimitive = categoryPrimitives[0]
              selectedProduct = {
                id: categoryPrimitive.id,
                description: categoryPrimitive.description,
                title: categoryPrimitive.title,
                variants: [],
                options: {},
                cdpUrl: categoryPrimitive.url,
                images: [getMultiSourceImageWithDefaults(categoryPrimitive)],
                type: categoryPrimitive.type,
              }
            }
            else {
              selectedProduct = defaultSelectedProduct
            }
          }
          // TODO: set selectedProduct to category data with variants empty list
        }
      }

      const processCampaignImages = (campaignMedias: PageConfig['campaignMedias']) => {
        return campaignMedias.map(x => ({
          ...getMultiSourceImageWithDefaults(x),
          isVideo: x.isVideo,
          videoThumbnail: x.videoThumbnail,
        }) as any)
      }

      const variantStates: {[key: string]: DeepPartial<ConfigState>} = {}

      selectedProduct?.variants.forEach(variant => {
        variantStates[variant.optionsMatch] = {
          stateLoaded: true,

          variant,

          variantTitle: {
            active: true,
            value: variant.title,
          },

          comparePrice: {
            active: variant.comparePrice !== null,
            value: variant.comparePrice,
          },

          price: {
            active: true,
            value: variant.price,
            currency: variant.currency,
          },

          sku: {
            active: true,
            value: variant.sku,
          },

          addToCart: {
            active: true,
          },

          outOfStock: {
            active: !variant.available,
          },

          firstInStockVariant: selectedProduct?.firstInStockVariant,

          images: processCampaignImages(pageConfig.campaignMedias || [])
          .concat(...selectedProduct?.images || [])
          .concat(...variant.images || [])
        }
      })

      const hooks: Hook[] = []
      for (let i = 0; i < legacyFields.fishhooks.length; ++i) {
        const experienceHook = legacyFields.fishhooks[i]

        if (experienceHook.type === 'all_published_experiences') {
          const allPageConfigs = await getPageConfigs(shop)
            .then(exps => {
              if (!exps) return []
              return exps.filter(exp => exp.id !== pageConfig.id)
            }) // filter self

          const normalizedProducts: HookProduct[] = allPageConfigs.map((page) => {
            return {
              id: page.id || '',
              description: getElementFromHtmlString(page?.subheadline || ''),
              // TODO: cleanup experience hook type
              price: 0,
              comparePrice: null,
              currency: '',
              image: getMultiSourceImageWithDefaults(page?.campaignMedias[0]),
              title: page?.headline || '',
              url: '',
              experienceUrl: page?.pageUrl,
              type: 'STOREFRONT_PAGE_PRIMITIVE'
            }
          })

          const hook: Hook = {
            rawHook: experienceHook,
            products: normalizedProducts,
          }
          hooks.push(hook)
        }
        else if (experienceHook.type === 'manual_selection') {
          let normalizedProducts: NormalizedProductDetail[] = []
          const product_ids = experienceHook.product_ids || []
          if (product_ids.length > 0) {
            normalizedProducts = await getProductDetails(shop, {
              productIds: product_ids.map(p => p.product_id),
            })
          }

          const hook: Hook = {
            rawHook: experienceHook,
            products: normalizedProducts.map(product => {
              const variant = product.variants[0]
              if (!variant) throw new Error('At least one variant must exist')

              return {
                id: product.id,
                description: product.description,
                // TODO: what price should this be?
                price: variant.price,
                comparePrice: variant.comparePrice,
                currency: variant.currency,
                image: product.images[0],
                title: product.title,
                url: product.pdpUrl || product.cdpUrl || '',
                type: product.type,
              }
            })
          }
          hooks.push(hook)
        }
        else if (experienceHook.type === 'category' && !(shouldIgnoreDynamicCategoryHook && i === 0)) {
          const opts: GetProductPrimitivesOptions = {}
          opts.categoryIds = experienceHook.categories.map(x => x.category_id)
          opts.blacklistedProductIds = experienceHook.blacklisted_products.map((x) => x.product_id)

          const orderByMap: Record<SortBy['field'], GetProductPrimitivesOptions['sortBy']> = {
            price: 'PRICE',
            name: 'TITLE',
            sales: 'SALES',
            title: 'TITLE',
          }
          opts.sortBy = experienceHook.sort_by?.field && orderByMap[experienceHook.sort_by.field]
          opts.sortDir = experienceHook.sort_by?.direction === 'desc' ? 'DESC': 'ASC'
          opts.limit = DEFAULT_PRODUCT_LIMIT
          categoryProductQueryOpts.current = opts

          const normalizedProducts = opts.categoryIds?.length === 0 ? [] : await getProductPrimitives(shop, opts)
          const hook: Hook = {
            rawHook: experienceHook,
            products: normalizedProducts.map(product => {
              return {
                id: product.id,
                description: product.description,
                // TODO: what price should this be?
                price: product.price,
                comparePrice: product.comparePrice,
                currency: product.currency,
                image: product.featuredImage,
                title: product.title,
                url: product.url,
                type: product.type,
              }
            })
          }
          hooks.push(hook)
        }
        else if (experienceHook.type === 'manual_category_selection') {
          const opts: GetCategoryPrimitivesOptions = {
            categoryIds: experienceHook.categories.map(x => x.category_id),
          }
          const categories = await getCategoryPrimitives(shop, opts)
          const hook: Hook = {
            rawHook: experienceHook,
            categories: categories,
            products: [],
          }
          hooks.push(hook)
        }
        else if (experienceHook.type === 'same_category') {
          const productId = pageConfig.products?.[0]?.productId
          if (productId) {
            const opts: GetProductPrimitivesOptions = {}
            opts.productIds = [productId]
            opts.filterBy = 'SAME_CATEGORY'
            opts.limit = DEFAULT_PRODUCT_LIMIT
            const orderByMap: Record<SortBy['field'], GetProductPrimitivesOptions['sortBy']> = {
              price: 'PRICE',
              name: 'TITLE',
              sales: 'SALES',
              title: 'TITLE',
            }

            opts.sortBy = experienceHook?.sort_by?.field ? orderByMap[experienceHook.sort_by.field] : 'SALES'
            opts.sortDir = experienceHook?.sort_by?.direction ? (experienceHook?.sort_by?.direction === 'desc' ? 'DESC': 'ASC') : 'DESC'
            opts.limit = DEFAULT_PRODUCT_LIMIT

            categoryProductQueryOpts.current = opts
            sameCategoryProductQueryOpts.current = opts

            const normalizedProducts = await getProductPrimitives(shop, opts)

            const hook: Hook = {
              rawHook: experienceHook,
              products: normalizedProducts.map(product => {
                return {
                  id: product.id,
                  description: product.description,
                  // TODO: move same_category response into own type
                  price: product.price,
                  comparePrice: product.comparePrice,
                  currency: product.currency,
                  image: product.featuredImage,
                  title: product.title,
                  url: product.url,
                  type: product.type,
                }
              })
            }
            hooks.push(hook)
          }
          else {
            console.warn('unhandled hook type:', experienceHook.type)
            console.warn('if this values is true, category hook was skipped on purpose:', shouldIgnoreDynamicCategoryHook)
          }
        }
        else if (experienceHook.type === 'html') {
          hooks.push({
            rawHook: experienceHook,
            products: [],
            htmls: experienceHook.htmls.map((htmlObj)=> <Embeded html={htmlObj.html} />)
          })
        }
        else if (experienceHook.type === 'newsletter') {
          hooks.push({
            rawHook: experienceHook,
            products: [],
          })
        }
        else if (experienceHook.type === 'notify_out_of_stock') {
          hooks.push({
            rawHook: experienceHook,
            products: [],
          })
        }
        else {
          console.warn('unhandled hook type:', experienceHook.type)
        }
      }

      const options = deepCloneJson(getOptionsList(selectedProduct?.options || {}))
      const allOptionsMatches: string[] = cartesian(...options.map(o => o.values)).map(o => o.join(','))

      const optionsMatch = allOptionsMatches.find(optionsMatch => {
        if (!variantStates[optionsMatch]) return false
        return !variantStates[optionsMatch].outOfStock?.active
      }) || allOptionsMatches[0] || ''

      // mutate options to select those values of the first available variant
      const splitOptionsMatch = optionsMatch.split(',')
      options.forEach((option, i) => option.value = splitOptionsMatch[i])

      const config: DeepPartial<Config> = {
        baseState: {
          options: options.reduce((acc: ConfigState['options'], el: OptionType) => {acc[el.name] = el; return acc}, {}),

          title: {
            active: true,
            value: selectedProduct?.title || '',
          },

          campaignName: {
            active: true,
            value: pageConfig.headline,
          },

          campaignDescription: {
            active: pageConfig.subheadline !== '',
            value: pageConfig.subheadline,
          },

          quantity: {
            active: true,
          },

          images: processCampaignImages(pageConfig.campaignMedias || [])
            .concat(...selectedProduct?.images || []),

          description: {
            active: true,
            value: selectedProduct?.description || <span />,
          },
        },

        variantStates: variantStates,

        editedState: {
          hooks,
        },
      }

      const configAction: ConfigAction = {
        type: 'setPartialConfig',
        override: true,
        value: config,
      }

      configDispatch(configAction)
    } catch (error) {
      console.warn('In error catch mode, no data on screen')
      logging(error, { tags: { section: 'asyncStuff'} })
    }
    }
    setLastProcessedHash(hash)
    asyncStuff()
  }, [pageConfig, lastProcessedHash, shop, legacyFields])

  // NOTE: state is composed out of several layers of config
  useEffect(() => {
    const {
      baseState,
      editedState,
      variantStates,
    } = config

    const getNextMatchingVariantStateMutation = (optionsList: OptionType[]): string | null => {
      for (let i = optionsList.length - 1; i >= 0; i--) {
        const option = optionsList[i]
        for (const value of option.values) {
          optionsList[i].value = value // mutation
          const optionsMatch = getOptionsMatch(optionsList)
          if (variantStates[optionsMatch]) {
            return optionsMatch
          }
        }
      }
      return null
    }

    const intermediateState = merge.withOptions(
        { mergeArrays: false },
        initialState,
        baseState,
        editedState,
        { variantStates }
    )

    const newOptionsList = deepCloneJson(getOptionsList(intermediateState.options))
    let optionsMatch = getOptionsMatch(newOptionsList)

    if (!variantStates[optionsMatch]) {
      const nextMatch = getNextMatchingVariantStateMutation(newOptionsList)
      optionsMatch = nextMatch || optionsMatch
    }

    const optionsState = newOptionsList.reduce((k: ConfigState['options'], v: OptionType) => {
      k[v.name] = v
      return k
    }, {} as ConfigState['options'])

    const variantState = variantStates[optionsMatch] || {}

    const newState = merge.withOptions(
        { mergeArrays: false },
        intermediateState,
        variantState,
        optionsState
    )

    setState(newState)
  }, [config])

  return (
    <ConfigContext.Provider
      value={{
        searchParams,

        shop,
        shopDomain,
        vendor,

        experienceFolderUrl,

        logoUrl,
        setLogoUrl,

        primitiveSettings,
        setPrimitiveSettings,

        state,
        configDispatch,

        pageConfig,
        setPageConfig,

        footerConfig,
        setFooterConfig,

        headerConfig,
        setHeaderConfig,

        cartItems,
        updateCartItem,

        categoryProductQueryOpts: categoryProductQueryOpts.current,
        sameCategoryProductQueryOpts: sameCategoryProductQueryOpts.current,

        legacyFields,
        setLegacyFields,
      }}
    >
      {children}
    </ConfigContext.Provider>
  )
}
