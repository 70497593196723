import { hostOverrideName } from "./api/config"
import { getShopUrl } from "./getShopUrl"

export const getExperienceFolderUrl = (searchParams: URLSearchParams) => {

  let protocol = window.location.protocol
  // NOTE: use override if it exists
  const domain = searchParams.get(hostOverrideName) || window.location.host
  const shopUrl = getShopUrl(searchParams)

  if (window.location.hostname === 'localhost') {
    protocol = 'https:'
  }
  return `${protocol}//${domain}/experiences/${shopUrl}`
}
