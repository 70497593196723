import { ProductField } from '../api/components'
import { PageConfig } from '../config'

export const getInitialPageConfig = (): PageConfig => {
  const now = new Date().getTime()
  return ({
      id: '',
      internalName: '',
      headline: '',
      subheadline: '',
      layout: '',
      startDate: 1,
      createdAt: now,
      updatedAt: now,

      redirectUrl: '',
      pageUrl: '',
      pageUrlWithUtms: '',
      utmCampaign: '',
      utmSource: '',
      utmMedium: '',

      components: [],
      componentIds: [],
      componentOrderId: '',
      mlOrderingUsed: [],
      pageComponentOverrides: {},

      products: [],
      categories: {
        uiSettings:{
          fields: [] as ProductField[]
        },
        apiSettings: {
          categoryIds: [] as string[]
        },
      },
      campaignMedias: [],
    }
  )
}
