import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import { ConfigContextProvider } from './config/context'
import App from './App'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import ErrorBoundry from '../src/components/ErrorBoundry'
// import reportWebVitals from './reportWebVitals'
import './index.css'

Sentry.init({
  dsn: "https://0387e457688a4e3f817b29a016207439@o4504523710332928.ingest.sentry.io/4504589868793856",
  integrations: [
    new Integrations.BrowserTracing(),
    new Sentry.Integrations.Breadcrumbs({console: false}),
    new Sentry.Replay()
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.8,
  environment: process.env.REACT_APP_SENTRY_ENV,
  release: process.env.REACT_APP_SENTRY_RELEASE,

   // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.5,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <Sentry.ErrorBoundary fallback={<ErrorBoundry />}>
  <React.StrictMode>
    <Router>
      <ConfigContextProvider>
        <App />
      </ConfigContextProvider>
    </Router>
  </React.StrictMode>
  </Sentry.ErrorBoundary>
)

// reportWebVitals(console.log)
