import { ExperienceConfig } from '../config'

export type LegacyFields = {
  status?: string,
  fishhooks: ExperienceConfig['fishhooks']
}

export const getInitialLegacyFields = (): LegacyFields => {
  return ({
    status: undefined,
    fishhooks: [],
  })
}
