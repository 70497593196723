import { productServiceUrl } from './config'
import { VariantDetail, ImageType, OptionValue } from '../config'
import { getCurrencySymbolFromCode, normalizeImage } from './getProductsByIds'
import { logging } from '../utils/logging'

export const normalizeVariantDetail = (variantDetail: VariantDetail): NormalizedVariantDetail => {
  return {

    id: variantDetail.id,
    productId: variantDetail.productId,
    productTitle: variantDetail.productTitle,
    externalId: variantDetail.externalId,
    sku: variantDetail.sku,
    title: variantDetail.title,
    price: variantDetail.price,
    comparePrice: variantDetail.comparePrice,
    currency: getCurrencySymbolFromCode(variantDetail.currencyCode),
    optionValues: variantDetail.optionValues,
    featuredImage: normalizeImage(variantDetail.featuredImage),
    images: variantDetail.images.map(normalizeImage),
    available: variantDetail.available,
    allowOosOrder: variantDetail.allowOosOrder,
    quantity: variantDetail.quantity,
  }
}

export type GetVariantDetailsOptions = {
  variantIds?: string[],
  variantExternalIds?: string[],
  limit?: number,
  offset?: number,
}

export const getVariantDetails = async (shop: string, options: GetVariantDetailsOptions) => {
  try {
    const {variantIds, variantExternalIds, limit, offset} = options

    // const variants = await
    const config : RequestInit = {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        shop,
      },
    }

    const query = {} as any
    if (variantIds) query['variantIds'] = variantIds.join(',')
    else if (variantExternalIds) query['variantExternalIds'] = variantExternalIds.join(',')
    if (limit) query['limit'] = limit
    if (offset) query['offset'] = offset

    const queryString = new URLSearchParams(query).toString()
    const url = `${productServiceUrl}/v1/variants/?${queryString}`

    const response: VariantDetail[] = await fetch(url, config)
    .then(async (response) => {
      if (!response.ok) {
        const errBody = await response.json()
        throw new Error(`request failed with status ${response.status}: ${errBody.error}`)
      }
      return response.json()
    })
    .catch(err => {
      throw new Error(`fetch request failed: ${err}`)
    })

    return response.map(normalizeVariantDetail)
  } catch (error) {
    logging(error, { tags: { section: 'getVariantDetails' } })
    return []
  }
}

export type NormalizedVariantDetail = {
  id: string
  productId: string
  productTitle: string
  externalId: string
  sku: string
  title: string
  price: number
  comparePrice: number | null
  currency: string
  optionValues: OptionValue[]
  featuredImage?: ImageType
  images: ImageType[]
  available: boolean
  allowOosOrder: boolean
  quantity: number
}