import {componentServiceBaseUrl} from "./config";
import {logging} from "../utils/logging";

export enum LineItemFieldEnum {
  PRODUCT_NAME = 'PRODUCT_NAME',
  VARIANT_NAME = 'VARIANT_NAME',
  ITEMIZED_OPTIONS = 'ITEMIZED_OPTIONS',
  LOW_INVENTORY = 'LOW_INVENTORY'
}

export type ImageFitType = 'contain' | 'cover' | 'fill'

export enum ImageFitEnum {
  CONTAIN = 'contain',
  COVER = 'cover',
  FILL = 'fill'
}

export type PrimitiveSettings = {
  lineItem: {
    uiSettings: {
      fields: LineItemFieldEnum[]
    }
  }
  product: {
    uiSettings: {
      imageFit: ImageFitEnum
      showImageSearch: boolean
    }
  }
  category: {
    uiSettings: {
      imageFit: ImageFitEnum
    }
  }
  storefront: {
    uiSettings: {
      imageFit: ImageFitEnum
    }
  }
}

export const defaultPrimitiveSettings: PrimitiveSettings = {
  lineItem: {
    uiSettings: {
      fields: [LineItemFieldEnum.PRODUCT_NAME, LineItemFieldEnum.ITEMIZED_OPTIONS],
    }
  },
  product: {
    uiSettings: {
      imageFit: ImageFitEnum.CONTAIN,
      showImageSearch: true
    }
  },
  category: {
    uiSettings: {
      imageFit: ImageFitEnum.CONTAIN
    }
  },
  storefront: {
    uiSettings: {
      imageFit: ImageFitEnum.CONTAIN
    }
  }
}

export const getPrimitiveSettings = async (shop: string, shopDomain: string) => {
  try {
    const config: RequestInit = {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'shop': shop,
        'shop-domain': shopDomain,
      },
      cache: 'default'
    }

    const url = componentServiceBaseUrl + '/v2/primitives/settings'
    const response: PrimitiveSettings = await fetch(url, config)
      .then(async (response) => {
        if (!response.ok) {
          const errBody = await response.json()
          throw new Error(`request failed with status ${response.status}: ${errBody.error}`)
        }
        return response.json()
      })
      .catch(err => {
        throw new Error(`fetch request failed: ${err}`)
      })

    return response

  } catch (error) {
    logging(error, {tags: {section: 'getPrimitiveSettings'}})
  }
}